import { ref } from 'vue'
import { useToggle } from '@vueuse/core'

const password = ref<string>('')
const [passwordEntered, togglePasswordEntered] = useToggle(false)

export const usePermissionsStore = () => {
    const setPassword = (value: string) => {
        password.value = value
        togglePasswordEntered()
    }

    return {
        password,
        setPassword,
        passwordEntered,
    }
}
